<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="filterParts"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="12" class="d-flex">
                        <h1 class="mx-4 my-n3">PART NUMBERS</h1>
                        <v-spacer />
                        <v-btn
                            rounded
                            color="primary"
                            @click="openDialogCreate"
                            class="mx-2"
                            v-if="createPermission"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!-- headers -->
            <template v-slot:[`header.createdOn`]="{ header }">
                <div class="d-flex">
                    <p>{{ header.text }}</p>
                    <v-btn
                        icon
                        class="mt-n2 text-uppercase"
                        @click="sortCreatedOn = !sortCreatedOn"
                    >
                        <v-icon v-if="sortCreatedOn">mdi-arrow-up</v-icon>
                        <v-icon v-else>mdi-arrow-down</v-icon>
                    </v-btn>
                </div>
            </template>
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="codeToFilter"
                    prepend-icon="mdi-filter"
                />
            </template>

            <template v-slot:[`header.product`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="productToFilter"
                    prepend-icon="mdi-filter"
                />
            </template>

            <template v-slot:[`header.material`]="{ header }">
                <v-text-field
                    :label="header.text"
                    v-model="materialToFilter"
                    prepend-icon="mdi-filter"
                />
            </template>
            <!--Items--->
            <template v-slot:[`item.createdOn`]="{ item }">
                <div class="d-flex justify-left">
                    <p class="px-0 py-0 my-0">
                        {{ formatDate(item.createdOn) }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-left">
                    <p class="px-0 py-0 my-0">
                        {{ item.createdByName }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.code`]="{ item }">
                <div class="d-flex justify-left ml-9">
                    <p class="px-0 py-0 my-0 text-capitalize">
                        {{ item.code }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.product`]="{ item }">
                <div class="d-flex justify-left ml-9">
                    <p class="px-0 py-0 my-0">
                        {{ item.product }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.material`]="{ item }">
                <div class="d-flex justify-left ml-9">
                    <p class="px-0 py-0 my-0">
                        {{ item.material }}
                    </p>
                </div>
            </template>
            <template
                v-if="this.deletePermission"
                v-slot:[`item.actions`]="{ item }"
            >
                <div class="d-flex justify-center">
                    <v-btn small icon @click="openDialogDelete(item)">
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>

        <!--Part Number Delete-->
        <v-dialog
            :retain-focus="false"
            v-model="openPartNumberDelete"
            persistent
            width="40%"
        >
            <PartNumberDelete
                v-if="openPartNumberDelete"
                :item="selectedItem"
                @closeDialog="closeDialogDelete"
                @deletePartNumber="deletePartNumber"
                :projectId="this.projectId"
            />
        </v-dialog>

        <!--Part Number Create-->
        <v-dialog
            :retain-focus="false"
            v-model="openPartNumberCreate"
            persistent
            width="40%"
        >
            <PartNumberCreate
                v-if="openPartNumberCreate"
                @closeDialog="closeDialogCreate"
                @createPartNumber="createPartNumber"
                :projectId="this.projectId"
            />
        </v-dialog>

        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script>
import API from '@/services/api'
import _ from 'lodash'
import { mapMutations } from 'vuex'
import moment from 'moment'
import PartNumberDelete from '@/components/StockRoom/PartNumberDelete.vue'
import PartNumberCreate from '@/components/StockRoom/PartNumberCreate.vue'
import { firestore } from '../../services/firebase'

export default {
    name: 'PartNumbers',
    props: {
        projectId: {
            type: String,
            default: () => undefined,
        },
    },
    components: {
        PartNumberDelete,
        PartNumberCreate,
    },
    data: () => ({
        activateArrow: true,
        activateAlert: false,
        openPartNumberDelete: false,
        openPartNumberCreate: false,
        partNumbersInfo: null,
        loading: false,
        sortCreatedOn: false,
        selectedItem: {},
        createdBy: null,
        headers: [
            {
                text: 'CREATED ON',
                value: 'createdOn',
                align: 'left',
                sortable: false,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'left',
                sortable: false,
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'left',
                sortable: false,
            },
            {
                text: 'PRODUCT',
                value: 'product',
                align: 'left',
                sortable: false,
            },
            {
                text: 'MATERIAL',
                value: 'material',
                align: 'left',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        partNumbers: [],
        users: [],
        user: undefined,
        search: '',
        alertMessage: '',
        originalItem: {},
        approveStock: undefined,
        codeToFilter: undefined,
        productToFilter: undefined,
        materialToFilter: undefined,
        deletePermission: false,
        createPermission: false,
    }),
    computed: {
        filterParts() {
            let conditions = []

            this.sortByCreatedOn()

            if (this.codeToFilter) {
                conditions.push(this.filterForCode)
            }

            if (this.productToFilter) {
                conditions.push(this.filterForProduct)
            }

            if (this.materialToFilter) {
                conditions.push(this.filterForMaterial)
            }

            if (conditions.length > 0) {
                return this.partNumbers.filter(partNumber => {
                    return conditions.every(condition => {
                        return condition(partNumber)
                    })
                })
            }
            return this.partNumbers
        },
    },
    async mounted() {
        const {
            data: { user },
        } = await API.getMyInfo()
        this.user = user
        const {
            data: { users },
        } = await API.getUsers()
        this.users = users
        this.createPermission = user.permissions.includes('createPartNumbers')
        this.deletePermission = user.permissions.includes('deletePartNumbers')
        await this.getPartNumbers()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        filterForCode(item) {
            return (
                item.code &&
                item.code
                    .toLowerCase()
                    .includes(this.codeToFilter.toLowerCase())
            )
        },
        filterForProduct(item) {
            return (
                item.product &&
                item.product
                    .toLowerCase()
                    .includes(this.productToFilter.toLowerCase())
            )
        },
        filterForMaterial(item) {
            return (
                item.material &&
                item.material
                    .toLowerCase()
                    .includes(this.materialToFilter.toLowerCase())
            )
        },
        formatDate(date) {
            return moment
                .unix(date._seconds || date.seconds)
                .format('YYYY-MM-DD')
        },
        sortByCreatedOn() {
            if (this.sortCreatedOn) {
                this.partNumbers.sort(
                    (a, b) => a.createdOn.seconds - b.createdOn.seconds
                )
            } else {
                this.partNumbers.sort(
                    (a, b) => b.createdOn.seconds - a.createdOn.seconds
                )
            }
        },
        async getPartNumbers() {
            try {
                this.loading = true
                const collectionRef = firestore
                    .collection('companies')
                    .doc(this.user.companyId)
                    .collection('projects')
                    .doc(this.projectId)
                    .collection('partNumbers')

                collectionRef.onSnapshot(querySnapshot => {
                    this.partNumbers = []

                    querySnapshot.forEach(doc => {
                        const data = doc.data()
                        this.partNumbers.push(data)
                    })

                    this.partNumbers.sort((a, b) => a.number - b.number)

                    this.partNumbers.forEach(partNumber => {
                        partNumber.createdByName = this.getUserName(
                            partNumber.createdBy
                        )
                    })

                    this.loading = false
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getUserName(id) {
            if (this.users.length > 0) {
                return this.users.find(user => user.id == id)?.name
            } else {
                return ''
            }
        },
        deletePartNumber(code) {
            const index = this.partNumbers.findIndex(item => item.code == code)
            if (index > -1) {
                this.partNumbers.splice(index, 1)
            }
            this.closeDialogDelete()
            this.alertMessage = 'Part Number was successfully removed'
            this.activateAlert = true
        },
        createPartNumber() {
            this.closeDialogCreate()
            this.alertMessage = 'Part Number was successfully created'
            this.activateAlert = true
        },
        openDialogDelete(item) {
            this.openPartNumberDelete = true
            this.selectedItem = _.cloneDeep(item)
        },
        closeDialogDelete() {
            this.openPartNumberDelete = false
            this.selectedItem = {}
        },
        openDialogCreate() {
            this.openPartNumberCreate = true
        },
        closeDialogCreate() {
            this.openPartNumberCreate = false
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}

thead {
    background: #eeeeee;
}

.v-input .v-label {
    font-size: 12px;
}
</style>
