<template>
    <div>
        <v-card id="details" :loading="loading">
            <v-card-title>
                <v-btn small icon @click="close" color="primary" class="mr-2">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
                <h3>Delete Part number</h3>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-subtitle>
                <div>
                    <p class="pa-0 mx-0 my-2">
                        <strong>CODE: </strong>{{ item.code }}
                    </p>
                </div>
                <div>
                    <p class="pa-0 mx-0 my-2">
                        <strong>PRODUCTO: </strong>{{ item.product }}
                    </p>
                </div>
                <div>
                    <p class="pa-0 mx-0 my-2">
                        <strong>MATERIAL: </strong>{{ item.material }}
                    </p>
                </div>
            </v-card-subtitle>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="red"
                    dark
                    class="mr-2 mb-3"
                    rounded
                    @click="deletePartNumber"
                    :loading="loading"
                >
                    DELETE
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'PartNumberDelete',
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        projectId: {
            type: String,
            default: undefined,
        },
    },
    data: () => ({
        loading: false,
        valid: false,
    }),
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('closeDialog')
        },
        async deletePartNumber() {
            try {
                this.loading = true
                await API.deletePartNumber(this.projectId, this.item.code)
                this.$emit('deletePartNumber')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
