<template>
    <div>
        <v-card id="details" :loading="loading">
            <v-card-title>
                <v-btn small icon @click="close" color="primary" class="mr-2">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
                <h3>New Part Number</h3>
            </v-card-title>
            <v-divider class="ml-4 mr-5 mb-5" />
            <v-card-text class="mt-n5">
                <v-form v-model="valid">
                    <v-row no-gutters class="ml-4 mr-5" ref="product">
                        <v-col cols="12">
                            <v-autocomplete
                                @change="deleteSearch"
                                :search-input.sync="search"
                                label="Product"
                                prepend-icon="mdi-barcode"
                                v-model="part.productId"
                                :items="products"
                                item-text="name"
                                item-value="id"
                                :rules="[rules.required]"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="ml-4 mr-5" ref="qty">
                        <v-col cols="6">
                            <v-autocomplete
                                @change="deleteMaterialSearch"
                                :search-input.sync="materialSearch"
                                label="Material"
                                prepend-icon="mdi-cube-outline"
                                v-model="part.material"
                                :items="materials"
                                item-text="material"
                                item-value="material"
                                :rules="[rules.required]"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="part.qty"
                                class="ml-3"
                                rows="4"
                                label="QTY"
                                :rules="[
                                    rules.required,
                                    rules.number,
                                    rules.min,
                                    rules.max,
                                ]"
                                prepend-icon="mdi-database-outline"
                                type="number"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    class="mr-2 mb-3"
                    rounded
                    :disabled="!valid"
                    @click="createPartNumber"
                    :loading="loading"
                >
                    CREATE PART NUMBER
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'PartNumberCreate',
    props: {
        projectId: {
            type: String,
            default: () => undefined,
        },
    },
    data: () => ({
        search: '',
        loading: false,
        valid: false,
        part: {
            productId: null,
            qty: 1,
        },
        rules: {
            required: v => !!v || 'The value is required!',
            number: v =>
                (!!v && typeof Number(v) == 'number') ||
                'The value must be a number',
            min: v => (!!v && v > 0) || 'The value must be higher than 0',
            max: v => (!!v && v < 101) || 'The value must be less than 101',
        },
        products: [],
        materials: [
            'ALUMINUM',
            'STAINLESS STEEL',
            'GALVANIZED STEEL',
            'ACM',
            'GLASS',
            'OTHER',
        ],
    }),
    async mounted() {
        await this.getProducts()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        getProducts: async function() {
            try {
                this.error = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { products },
                } = await API.getProducts()
                this.products = products.map(product => ({
                    name: product.name,
                    id: product.id,
                }))
            } catch (error) {
                this.error = true
                this.errorMsg = error.message
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('closeDialog')
        },
        deleteSearch() {
            this.search = ''
        },
        async createPartNumber() {
            try {
                this.loading = true
                await API.createPartNumber({
                    projectId: this.projectId,
                    productCode: this.part.productId,
                    qty: this.part.qty,
                    material: this.part.material,
                })
                this.$emit('createPartNumber')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
